.NavBarNew {
    padding: 0px 50px !important;
    padding: 0px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    letter-spacing: 1px;

    &.fixed {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 10;
        background: #1A1A1B;
        transition: 0s;
    }

    &.landing {
        // background: linear-gradient(180deg, #000000 22.92%, rgba(117, 115, 115, 0) 100%);
        background: transparent;
    }

    &.home {
        transition: .3s;
        background: rgba(26,26,27,.7);
        backdrop-filter:blur(31px);
    }

    .brandLogo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            cursor: pointer;
            &.showTitle{
                height: 60px;
                width: 60px;
                transition: 1s;
            }
            &.hideTitle{
                height: 0px;
                width: 0px;
                transition: 1s;
            }
        }

        .brandName {
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 20px;
            font-family: DashboardRegular;
            margin-bottom: 0px;
            margin-left: 10px;
            letter-spacing: 2px;

            &.showTitle {
                opacity: 1;
                transition: 1s;
            }

            &.hideTitle {
                transition: 1s;
                opacity: 0;
            }
        }
    }

    .menuIcon {
        position: fixed;
        right: 50px;
        top: 22px;
        font-size: 25px;
        cursor: pointer;
        margin-left: 20px;
    }

    .overlayMenu {
        height: 100vh;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 1);
        overflow-x: hidden;
        transition: .5s;
        &.openNavBar{
            width: 30%;
        }

        .overlayMenu-content {
            position: relative;
            top: 25%;
            width: 100%;
            text-align: center;
            margin-top: 30px;

            ul {
                list-style: none;
                padding: 0px;

                li {
                    cursor: pointer;
                    .link {
                        padding: 8px;
                        text-decoration: none;
                        font-size: 22px;
                        color: #FFFFFF;
                        display: block;
                        transition: 0.3s;
                        font-family: ArchivoBlack;
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        opacity: .8;

                        &:hover,
                        &:focus {
                            opacity: 1;
                            background: linear-gradient(88deg, #8EE579 4.16%, rgba(61, 191, 29, 0) 224.38%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }

                    &.active {
                        .link {
                            opacity: 1;
                            background: linear-gradient(88deg, #8EE579 4.16%, rgba(61, 191, 29, 0) 224.38%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
            }
        }

        .menuClose {
            position: absolute;
            top: 0px;
            right: 45px;
            font-size: 40px;
            color: #ffffff;
            text-decoration: none;
            opacity: .6;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }

    .socialIcons {
        display: flex;
        justify-content: flex-end;
        margin-right: 0px;

        ul {
            display: flex;
            align-items: center;
            list-style: none;
            margin-bottom: 0px;

            li {
                margin: 0px 15px;
               .userAddressButton {
                    // padding:0 20px;
                    width: 189px;
                    cursor: pointer !important;
                    height: 44px;
                    color: #ffffff;
                    border-radius:40px;
                    text-transform:uppercase;
                    line-height: 40px;
                    box-shadow: none;
                    font-size: 14px;
                    font-family: ArchivoBlack;
                    background: rgba(85,192,59,.2);
                    border:1px solid #55c03b;
                    transition:.3s;
                    span{
                        padding-right: 5px;
                    }
                    .symbol{
                        padding-right: 0px;
                        font-family: system-ui;
                        font-size: 15px;
                    }

                    &:hover {
                        opacity: 1;
                        cursor: default;
                        box-shadow: inset 245px 0 0 0 #55c03b;
                    }
                }
                .dropDownMenu{
                    background: rgba(0,0,0,.5);
                    width: 100%;
                    a{
                        font-size: 14px;
                        font-family: Archivo;
                    }
                }
                .wrongNetworkButton {
                    padding:0 30px;
                    pointer-events:none;
                    height: 44px;
                    color: #ffffff;
                    border-radius:40px;
                    text-transform:uppercase;
                    line-height: 48px;
                    box-shadow: none;
                    font-size: 16px;
                    font-family: Archivo;
                    background: rgba(72,52,212,.2);
                    border:1px solid rgba(72,52,212,1);

                    &:hover {
                        opacity: 1;
                        cursor: default;
                    }
                }
                .connectButton{
                    padding:0 20px;
                    cursor: pointer !important;
                    height: 44px;
                    color: #ffffff;
                    border-radius:40px;
                    text-transform:uppercase;
                    line-height: 46px;
                    box-shadow: none;
                    font-size: 14px;
                    font-family: Archivo;
                    background: rgba(85,192,59,.1);
                    border:1px solid #55c03b;
                    transition:.3s;

                    &:hover {
                        cursor: default;
                        // opacity: .8;
                        box-shadow: inset 245px 0 0 0 #55c03b;
                    }
                }

                a {
                    font-family: ArchivoBlack;
                    color: #ffffff;
                    font-size: 18px;

                    &:hover {
                        text-decoration: none;
                        background: linear-gradient(90deg, #34A300 0%, #75DB73 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                &.active {
                    a {
                        background: linear-gradient(90deg, #34A300 0%, #75DB73 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }

            .youtubeIcon {
                margin-right: 15px;
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .NavBarNew {
        padding: 0px 10px !important;
        justify-content:center;

        &.landing{
            background: #1A1A1B;
            visibility: hidden;
            .brandLogo{
                transition: 1s;
                display: none;
            }
            .menuIcon{
                transition: 1s;
                display: none;
            }
        }
        &.home{
            background: rgba(26,26,27,.7);
            backdrop-filter:blur(31px);
            .brandLogo{
                .logo {
                    padding-top: 15px;
                }
                display: block;
            }
            .menuIcon{
                display: block;
            }
        }
       
        .menuIcon{
            right: 15px;
            top: 20px;
            margin-left: 0px;
        }
        .overlayMenu {
            height: 100vh;
            &.openNavBar{
                width: 100%;
            }
            a {
                font-size: 20px
            }
            .menuClose {
                font-size: 40px;
                top: 0px;
                right: 20px;
            }
        }
        .socialIcons {
            display: none;
        }
    }
}