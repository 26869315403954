.LeaderBoards {
    width: 93%;
    margin: auto;
    .tableHeader {
        background: #676093 !important;
        color: #ffffff !important;
        font-weight: bold !important;
        text-transform: uppercase;
    }

    .TablePagination {
        background: #ffffff;
    }

    .hd {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        flex-direction: row;

        .LeaderBoardsDate {
            // background: yellow;
            color: #fff;
            // text-align: center;
            width: 300px;
            height: 20px;
        }
    }
}

.searchTerm::-webkit-input-placeholder {
    font-size: 12px;
}

.searchTerm:-ms-input-placeholder {
    font-size: 12px;
}

.searchTerm::placeholder {
    font-size: 12px;
}

.sb-example-1 .search {
    width: 300px;
    position: relative;
    display: flex;
}

.sb-example-1 .searchTerm {
    width: 100%;
    border: 3px solid #00B4CC;
    border-right: none;
    padding: 5px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #000;
}

.sb-example-1 .searchTerm:focus {
    color: #000000;
}

.sb-example-1 .searchButton {
    width: 40px;
    height: 50px;
    border: 1px solid #00B4CC;
    background: #00B4CC;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
}

@media only screen and (max-width:600px) {
    .brandName {
        display: none;
    }

    .LeaderBoards {

        .tableHeader {
            background: #676093 !important;
            color: #ffffff !important;
            font-weight: bold !important;
            text-transform: uppercase;
        }

        .TablePagination {
            background: #ffffff;
        }

        .hd {
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            margin-top: 0px;
            flex-direction: column;

            .LeaderBoardsDate {
                // background: yellow;
                color: #fff;
                text-align: center;
                margin-bottom: 10px;
                width: 300px;
                height: 20px;
            }
        }
    }

    .sb-example-1 {
        margin-bottom: 0px;
    }
}
.MuiTableSortLabel-root:hover{
    color: #fff !important;
}